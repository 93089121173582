@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;400;700&display=swap');

* {
    font-family: 'IBM Plex Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    background-color: #f9f9f9 !important;
}





.left {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #333;
    text-decoration: none;
    display: flex;
}

.navbar .left {
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}

.set {
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    border-radius: 50%;
}

.set a {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    margin-left: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.footer-text {
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer-text p {
    font-weight: 500;
    font-size: 16px;
    /*line-height: 10px;*/
    margin-bottom: 0;
    color: #ababab;
}


.home-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

a {
    cursor: pointer;
}

.logo-picture {
    margin-right: 20px;
    min-height: 45px;
    min-width: 45px;
}

h2.logo-text {
    font-size: 16px;
    max-width: 276px;
    text-decoration: none !important;
    color: #333;
    line-height: 20px;
    margin-top: 15px;
}

.nav_ul a {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    color: #333 !important;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .dropdown-item:hover {
    background-color: #242623;
    color: #fff !important;
}

.menu-right {
    position: absolute;
    right: 2%;
    gap: 20px;
    font-size: 15px;
}

ul.navbar-nav li {
    position: relative;
}

@media (max-width: 992px) {
    .menu-right {
        position: initial;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .navbar-nav {
        background-color: #EBEBEB !important;
    }

    .menu-right {
        gap: 20px !important;
    }
}


@media screen and (min-width: 992px) {
    .navbar {
        padding: 0;
    }

    .navbar-expand-lg .navbar-collapse {
        margin-left: -12%;
    }

}

.navbar .navbar-nav .nav-link,
.navbar .navbar-nav .dropdown-item {
    padding: 1em;
    border-radius: 25px;
}

@media (max-width: 1200px) {
    /*.navbar .navbar-nav .nav-link {*/
    /*    padding: 10px 5px;*/
    /*}*/
    .menu-right {
        gap: 5px;
    }
}

.dropdown-menu {
    border-radius: 5px;
}

.navbar-toggler {
    position: absolute;
    right: 30px;
}

@media screen and (max-width: 992px) {
    .navbar-brand {
        padding-left: 0.7em;
    }
}

.btn1 {
    border: 0;
    padding: 0.5rem 1.8rem;
    color: #fff;
    background-color: #01193c;
}

.btn2 {
    border: 0;
    padding: 0.5rem 1.2rem;
    color: #fff;
    background-color: #f60838;
}

.btn-light {
    --bs-btn-color: #64748b;
    --bs-btn-bg: #f1f5f9;
    --bs-btn-border-color: #f1f5f9;
    --bs-btn-hover-color: #334155;
    --bs-btn-hover-bg: #e2e8f0;
    --bs-btn-hover-border-color: #e2e8f0;
    --bs-btn-active-color: #334155;
    --bs-btn-active-bg: #e2e8f0;
    --bs-btn-active-border-color: #e2e8f0;
    --bs-btn-disabled-color: #334155;
    --bs-btn-disabled-bg: #f1f5f9;
    --bs-btn-disabled-border-color: #f1f5f9;
}

.Footer {
    padding: 2rem 0;
    background: #333333;
    color: #fff;
}

.ft-1 h3 {
    font-weight: 700;
    font-family: cursive;
    letter-spacing: 2px;
    padding: 0.5rem 0;
}

.ft-1 span {
    color: #f60838;
}

.ft-1 p {
    padding: 0;
    font-weight: 500;
}

.footer-icons i {
    padding: 0.4rem 0.5rem;
    background: #e1e1e1;
    color: #f60838;
    margin: 0 0.5rem;
    border-radius: 50%;
}

.footer-icons i:hover {
    background: #f60838;
    color: #fff;
    transition: 0.6s;
    cursor: pointer;
}

.Footer h5 {
    color: #A6A6A8;
    font-weight: bold;
}

.ft-2 ul {
    list-style: none;
    padding-left: 0;
}

.ft-2 ul li {
    padding: 0.35rem 0;
    font-weight: 500;
}

.ft-2 ul a {
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
}

.ft-3 p {
    font-weight: 500;
    padding: 0.1rem 0;
    font-size: 0.9rem;
}

.ft-3 i {
    padding-right: 0.5rem;
}

.Last-footer {
    background: #f60838;
    text-align: center;
    padding: 1rem 0;
}

.Last-footer p {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 700;
}


/* carousel */
.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.carousel .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}

.carousel .list .item img {
    object-fit: cover;
    background-size: cover;
    background-position: center;
    min-height: 100%;
    min-width: 100%;
}

.carousel .list .item .content {
    position: absolute;
    bottom: 25%;
    width: 1140px;
    max-width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
}

.carousel .list .item .author {
    font-weight: bold;
    letter-spacing: 10px;
}

.carousel .list .item .title,
.carousel .list .item .topic {
    font-size: 3em;
    font-weight: bold;
    line-height: 1.3em;
}

.carousel .time p:first-child {
    font-size: 3em;
    font-weight: bold;
    line-height: 0.8em;
}

.carousel .time p:last-child {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 0.8em;
}

.carousel .list .item .topic {
    color: #f1683a;
}

.carousel .list .item .buttons {
    display: grid;
    grid-template-columns: repeat(2, 130px);
    grid-template-rows: 40px;
    gap: 5px;
    margin-top: 20px;
}

.carousel .list .item .buttons button {
    border: none;
    background-color: #eee;
    letter-spacing: 3px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 16px;
}

.carousel .list .item .buttons button:nth-child(2) {
    background-color: transparent;
    border: 1px solid #fff;
    color: #eee;
}

/* thumbail */
.carousel .thumbnail {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
}

.carousel img {
    border-radius: 0 !important;
}

.carousel .thumbnail .item {
    width: 150px;
    height: 220px;
    flex-shrink: 0;
    position: relative;
}

.carousel .thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel .thumbnail .item .content {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}

.carousel .thumbnail .item .content .title {
    font-weight: 500;
}

.carousel .thumbnail .item .content .description {
    font-weight: 300;
}

/* arrows */
.arrows {
    /*position: absolute;*/
    z-index: 100;
    width: 300px;
    max-width: 30%;
    display: flex;
    align-items: center;
    gap: 10px;
    bottom: 5px;
    left: 48%;
}

.arrows #prev {
    position: absolute;
    z-index: 100;
    top: 48%;
    left: 5px;
}

.arrows #next {
    position: absolute;
    z-index: 100;
    top: 48%;
    right: 5px;
}

.arrows button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #333333;
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
}

.arrows button:hover {
    background-color: #fff;
    color: #333333;
    /*font-weight: 500;*/
}

/* animation */
.carousel .list .item:nth-child(1) {
    z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons,
.carousel .time {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}

@keyframes showContent {
    to {
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.carousel .list .item:nth-child(1) .content .title {
    animation-delay: 1.2s !important;
}

.carousel .list .item:nth-child(1) .content .topic {
    animation-delay: 1.4s !important;
}

.carousel .list .item:nth-child(1) .content .des {
    animation-delay: 1.6s !important;
}

.carousel .list .item:nth-child(1) .content .buttons,
.carousel .time {
    animation-delay: 1.8s !important;
}

/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
    top: 50px;
    min-width: 150px;
    min-height: 220px;
    position: absolute;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}

.carousel .content :where(.title, .time p) {
    text-shadow: 2px 2px #000;
}

@keyframes showImage {
    to {
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}

.carousel.prev .list .item img {
    z-index: 100;
}

@keyframes showThumbnail {
    from {
        width: 0;
        opacity: 0;
    }
}

.carousel.next .thumbnail {
    animation: effectNext .5s linear 1 forwards;
}

@keyframes effectNext {
    from {
        transform: translateX(150px);
    }
}

/* running time */

.carousel .time {
    position: absolute;
    z-index: 1000;
    right: 20%;
    bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel.next .time,
.carousel.prev .time {
    animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
    from {
        width: 100%
    }
    to {
        width: 0
    }
}


/* prev click */

.carousel.prev .list .item:nth-child(2) {
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}

@keyframes outFrame {
    to {
        width: 150px;
        height: 220px;
        bottom: 50px;
        left: 50%;
        border-radius: 20px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1) {
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}

.carousel.next .arrows button,
.carousel.prev .arrows button {
    pointer-events: none;
}

.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
    animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
    to {
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}

@media screen and (max-width: 678px) {
    .carousel .list .item .content .title,
    .carousel .time p:first-child {
        font-size: 17px;
        text-shadow: 1px 1px  #000000;
    }

    .carousel .time p:last-child {
        font-size: 12px;
        line-height: 0.5rem;
    }

    .navbar-toggler-icon {
        width: 1.2em;
        height: 1.2em;
    }

    .navbar-toggler {
        right: 15px;
    }

    .logo-picture {
        margin-right: 10px;
    }

    .navbar .left {
        padding-left: 10px;
    }
}

select {
    border-radius: 5px;
}

.header {
    position: relative;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0 6px 20px rgba(165, 166, 180, 0.13);
    min-height: 56px;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.navbar {
    box-shadow: 0 6px 20px rgba(165, 166, 180, 0.13);
    padding-top: 0;
    padding-bottom: 0;
}

.main-container {
    background-color: #EBEBEB;
    width: 100%;
}

.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

@media screen and (max-width: 420px) {
    h2.logo-text {
        max-width: 210px;
        font-size: 14px;
    }
}