
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Prata&display=swap');

:root {
    --primary-color: #2bcbba;
    --light-color: #d1d8e0;
    --grey-color: #687080;
    --red-color: #ff4757;
    --flashwhite-color: #f1f2f6;
    --white-color: #ffffff;
    --dark-color: #212121;

    --primary-font: 'Montserrat', sans-serif;
    --second-font: 'Prata', serif;
    --fs14: 14px;
    --fw600: 600;

    --transition-color: color 0.3s;
    --transition-border: border 0.3s;
    --transition-transform: transform 0.3s;
    --transition-background: background-color 0.3s;

    --shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
    --fade: fade-bottom 0.3s cubic-bezier(0.39, 0.575, 0.0565, 1) both;

    --swiper-pagination-bottom: -8px;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
}

*, ::before, ::after {
    box-sizing: border-box;
}

body {
    font-family: var(--primary-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    color: var(--dark-color);
    background-color: var(--white-color);
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    vertical-align: middle;
    border-radius: 15px;
}

ul {
    list-style: none;
}

p {
    margin: 0 0 15px;
}

input, textarea, select {
    font: inherit;
    width: 100%;
    border-radius: 10px !important;
}

input[type=checkbox], input[type=radio] {
    width: auto;
}

input::placeholder, textarea::placeholder {
    color: inherit;
}

button {
    font: inherit;
}

h1, h2, h3, h4 {
    font-family: var(--second-font);
    font-weight: 400;
    line-height: normal;
}

h1 {
    font-size: 2.75rem
}

h2 {
    font-size: 2.5rem
}

h3 {
    font-size: 2rem
}

h4 {
    font-size: 1rem
}

.container {
    max-width: var(--mx-width, 1200px);
    padding: 40px var(--gutter, 15px);
    margin: 0 auto;
}

.item-floating {
    position: absolute;
    font-size: 10px;
    font-weight: var(--fw600);
    top: -11px;
    right: -10px;
    background-color: var(--primary-color);
    color: var(--white-color);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.title {
    font-size: clamp(30px, -0.875em + 8.333vw, var(--fs-max, 40px));
}

.grey-link {
    color: inherit;
    transition: var(--transition-color);
}

.grey-color, .grey-link:hover {
    color: var(--grey-color);
}

input.checker {
    position: relative;
    padding: 5px;
    margin: 0 5px 0 2px;
    cursor: pointer;
}

input.checker::before, .input.checker::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
}

input.checker::before {
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    background-color: var(--white-color);
    border: 1px solid var(--grey-color);
    border-radius: 50%;
    transition: var(--transition-border);
}

.input.checker::after {
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%) scale(0);
    background-color: var(--dark-color);
    border-radius: 50%;
    transition: var(--transition-transform);
}

.input.checker:checked::after {
    transform: translate(-50%, -50%) scale(0);
}

.input.checker:checked::before {
    background-color: var(--dark-color);
}

/*
Grouped selector
*/
.item-floating, .header-center .branding {
    display: flex;
    align-items: center;
    justify-content: center;

}


/*------*/
/*Header*/
/*------*/
.inner-header {
    line-height: 80px;
}

.inner-header :where(.wrap, .menu > ul), .list-inline > ul {
    display: flex;
    align-items: center;

}

.header-left, .header-right {
    flex-grow: 1;
}

.header-center nav {
    display: none;
    grid-template-columns: 1fr 1fr;
    gap: 180px;
    max-width: 720px;
}

.header-center .branding {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.header-center .branding a {
    font-family: var(--second-font);
    font-size: 30px;
    line-height: inherit;
    pointer-events: auto;
}

.header-center .menu > ul > li > a {
    font-size: var(--fs14);
    font-weight: var(--fw600);
    text-transform: uppercase;
    padding: 0 15px;
    display: flex;
    gap: 15px;
}

.header-right ul {
    justify-content: flex-end;
}

.list-inline a,
.menu-trigger,
.close-trigger {
    position: relative;
    font-size: 24px;
    padding: 0 10px;
    line-height: 1;
    display: flex;
    transition: var(--transition-color);
}

.list-inline a:hover,
.menu-trigger:hover {
    color: var(--light-color) !important;
}

.header-left .list-inline,
.page-category .sidebar .widget input[type='checkbox'] {
    display: none;
}

:where(.header-left, .header-right) li {
    display: flex;
    align-items: center;
    height: 80px;
}

.list-block a {
    position: relative;
    font-size: var(--fs14);
    color: var(--grey-color);
    padding: 5px 0;
    display: inline-block;
    transition: var(--transition-color);
}

.list-block a:hover {
    color: var(--dark-color);
}

.list-block a::before {
    content: '';
    position: absolute;
    background-color: var(--dark-color);
    left: -15px;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: width 0.3s, height 0.3s;
}

.list-block a:hover::before {
    width: 8px;
    height: 8px;
}


@keyframes fade-bottom {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.dot-title {
    font-family: var(--primary-color);
    font-size: var(--fs14);
    font-weight: var(--fw600);
    line-height: 34px;
}

.dot-title a {
    transition: var(--transition-color);
}

/*
Header Product
*/

.dotgrid .wrapper {
    display: var(--display, grid);
    --grid-col: 175px;
    grid-template-columns: repeat(auto-fit, minmax(min(var(--grid-col), 100%), 1fr));
    gap: var(--gutter, 30px);
}

.dotgrid .dot-image {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 15px;
}

.dotgrid .dot-image > a,
.dotgrid .thumbnail.hover,
.dotgrid .dot-image .label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.dotgrid .dot-image > a {
    z-index: 1;
}

.dotgrid .thumbnail.hover {
    opacity: 0;
    transition: opacity 0.75s ease, transform 1.1s cubic-bezier(0.15, 0.75, 0.5, 1) 0s;
    backface-visibility: hidden;
    transform: translateZ(0);
}

.actions {
    z-index: 2;
    top: auto;
    bottom: 20px;
    transform: translate3d(0, 100%, 0);
    transform: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.dot-image:hover .actions {
    opacity: 1;
    transform: translateZ(0);
    overflow: hidden;
}

.dot-image:hover {
    transform: scale(1.05);
}

.actions ul,
.dotgrid .dot-image .label {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.actions ul li a,
.dotgrid .dot-image .label span {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: var(--white-color);
    border-radius: 50%;
    transition: var(--transition-background), var(--transition-color);
}

.actions ul li a:hover {
    background-color: var(--dark-color);
    color: var(--white-color) !important;
}

.dotgrid .dot-image .label {
    top: auto;
    bottom: 20px;
}

.dotgrid .dot-image .label span {
    font-size: 12px;
    font-weight: var(--fw600);
    background-color: var(--primary-color);
    color: var(--white-color);
}

.dotgrid .dot-info {
    text-align: center;
}

.overlay,
.data-popup,
.cart-menu,
.page-category .sidebar,
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 7vh;
}

.overlay {
    background-color: var(--dark-color);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 999;
    transition: opacity 0.3s, visibility 0.3s;
}

.overlay.active {
    opacity: 0.75;
    visibility: visible;
    pointer-events: all;
}

.scrollto > .wrapper {
    --display: flex;
    padding-bottom: 20px;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: inline mandatory;
}

.scrollto .wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.scrollto .wrapper::-webkit-scrollbar-thumb {
    --border-color: var(--white-color);
    background-color: var(--light-color);
    border: 3px solid var(--border-color);
    border-radius: 20px;
}

.scrollto .wrapper:hover::-webkit-scrollbar-thumb {
    background-color: var(--grey-color);
}

.scrollto .wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--grey-color);
}

/*
Header Search
*/
.inner-header .search-float {
    position: fixed;
    left: 0;
    right: 0;
    bottom: auto;
    height: 80px;
    background-color: var(--white-color);
    z-index: 1000;
    display: none;
}

form.search {
    position: relative;
}

form.search :where(i, .submit) {
    position: absolute;
    left: 0;
    top: 0;
}

form.search .input {
    outline: 0;
    padding: 15px 50px;
}

form.search i {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    cursor: pointer;
}

form.search :where(i:last-child,.submit) {
    left: auto;
    right: 0;
}

form.search .submit {
    border: 0;
    background-color: transparent;
    color: transparent;
    width: 50px;
    height: 100%;
    right: 0;
    z-index: 1;
    cursor: pointer;
}

form :where(input,textarea,select) {
    font-size: var(--fs14);
    border: 1px solid var(--light-color);
    outline: 0;
    background-color: transparent;
    padding: 10px 20px;
    transition: border 0.3s;
}

form :where(input,textarea,select):focus {
    border-color: var(--dark-color);
}

.inner-header .search .input {
    display: flex;
    border: 0;
    height: 80px;
    font-size: 18px;
}

.search-float.active {
    display: block;
}

/*
Header: Mobile menu
*/
.mobile-menu,
.cart-menu,
.page-category .sidebar {
    z-index: 1001;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}

.mobile-menu.active,
.cart-menu.active,
.page-category .sidebar.active {
    visibility: visible;
    opacity: 1;
}

.cart-menu .wrap,
.page-category .sidebar > .wrap {
    position: relative;
    max-width: calc(100% - 40px);
    width: 360px;
    height: 100%;
    background-color: var(--white-color);
    pointer-events: auto;
    transform: translateX(var(--transX, -100%));
    transition: var(--transition-transform);
}

.page-category .sidebar > .wrap {
    background-color: transparent;
}

.mobile-menu.active .wrap,
.cart-menu.active .wrap,
.page-category .sidebar.active > .wrap {
    transform: translateX(0);
}

.mobile-menu .close-trigger,
.mobile-menu .child-trigger,
.cart-menu .close-trigger {
    position: absolute;
    top: 10px;
    right: -40px;
    width: 40px;
    height: 40px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-category .sidebar .close-trigger {
    position: absolute;
    top: 50%;
    left: 155px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #474747;
    border-radius: 50%;
}

.mobile-menu .main-menu,
.cart-menu .cart-list,
.page-category .sidebar-content {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    /*overflow-y: scroll;*/
}

.page-category .sidebar .wrap .sidebar-content {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 0;
    width: 150px;
    font-size: 12px;
}


.mobile-menu nav > ul {
    padding: 20px 0;
}

.mobile-menu nav > ul > li > a {
    position: relative;
    padding: 12px 0;
    font-weight: 500;
    transition: var(--transition-color);
}

.mobile-menu nav li :where(li,a) {
    display: block;
}

.mobile-menu nav > ul > li > a:hover {
    color: var(--grey-color);
}

.mobile-menu .child-trigger {
    color: var(--dark-color);
    font-size: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: var(--transition-background);
    z-index: 1;
}

.mobile-menu .child-trigger:hover {
    background-color: var(--light-color);
}

.mobile-menu nav .sub-menu {
    padding-left: 15px;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: 0.3s ease-in-out;
}

.mobile-menu nav li.active .sub-menu {
    max-height: 1000px;
    visibility: visible;
}

.button > [class*=-btn] {
    font-size: var(--fs14);
    font-weight: var(--fw600);
    text-transform: uppercase;
    padding: 0 30px;
    display: inline-block;
    line-height: 42px;
    border-width: 2px;
    border-style: solid;
    transition: var(--transition-background) var(--transition-color) var(--transition-border);
}

.secondary-btn, .primary-btn {
    border-color: var(--dark-color);
    border-radius: 15px;
}

.primary-btn:hover,
.secondary-btn {
    background-color: var(--dark-color);
    color: var(--white-color) !important;
}

.secondary-btn:hover {
    background-color: transparent;
    border-color: var(--dark-color);
    color: var(--dark-color) !important;
}

.mobile-menu .button {
    margin-top: auto;
    padding: 20px 0 40px;
    text-align: center;
}

.mobile-menu .button a {
    display: block;
    margin-top: 10px;
}


/*
SLIDER
*/
.slider :where(.image, .ob-cover) {
    position: relative;
}

.slider .ob-cover {
    height: 430px;
}

.ob-cover img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slider .title-info {
    background-color: var(--white-color);
}

.slider .title-info .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-bottom: 5vw;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.slider .title-info .price {
    position: relative;
    font-size: 30px;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    background-color: var(--dark-color);
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.slider .title-info :where(span,h3,.button) {
    transform: translateY(30px);
    transition: transform 0.75s, opacity 0.75s, visibility 0.75s;
    opacity: 0;
    visibility: hidden;
}

.slider .swiper-slide-active .title-info :where(span,h3,.button) {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.slider .swiper-slide-active .title-info h3 {
    transition-delay: 0.5s;
}

.slider .swiper-slide-active .title-info .button {
    transition-delay: 0.75s;
}


/*
GUIDE
*/
.guide {
    background-color: var(--flashwhite-color);
}

.heading {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.guide .dotgrid .wrapper {
    --grid-col: 260px;
}

:is(.guide,.fromblog) .dot-image .thumbnail {
    position: relative;
    opacity: unset;
}

:is(.guide,.fromblog) .dot-image .thumbnail img {
    width: 100%;
}

.guide .dot-title {
    --fs14: 18px;
    --fw600: 400;
    padding: 10px 0;
}

.guide .dot-info {
    line-height: 1.4;
}

.scrollto .wrapper .item {
    width: 290px;
    scroll-snap-align: start;
    flex-shrink: 0;
}

.guide .scrollto .wrapper::-webkit-scrollbar-thumb {
    --border-color: #f0f9f8;
}

.page-home main > div:not(.slider,.frominsta), .inner-footer {
    padding: 100px 0;
}

/*
CAROUSEL
*/
.carousel .inner-wrapper {
    position: relative;
}

.carousel .dotgrid .wrapper {
    display: flex;
    grid-template-columns: unset;
    gap: unset;
}

.carousel .nav > div::after {
    content: none;
}

.carousel .nav > div {
    width: 40px;
    height: 40px;
    border: 1px solid var(--grey-color);
    border-radius: 50%;
    color: var(--dark-color);
    font-size: 20px;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition-background), var(--transition-color), var(--transition-border);
}

.carousel .nav > div:hover {
    color: var(--white-color);
    background-color: var(--dark-color);
    border-color: var(--dark-color);
}

/* ----------- *
    #BYCATS
* -------------*/
.bycats .heading {
    display: flex;
    justify-content: center;
}

.bycats .heading > span {
    font-family: var(--second-font);
    font-size: 32px;
    margin-right: 20px;
}

.bycats .heading > span,
.opt-trigger {
    height: 44px;
    display: flex;
    align-items: center;
    gap: 15px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

.sort-list .wrap {
    position: relative;
}

.sort-list .wrap ul {
    position: absolute;
    text-align: left;
    left: 0;
    right: 0;
    background-color: var(--white-color);
    box-shadow: var(--shadow);
    z-index: 10;
    display: none;
    animation: var(--fade);
}

.sort-list .wrap ul.show {
    display: block;
}

.sort-list ul li a {
    display: block;
    padding: 5px 20px;
    transition: var(--transition-background) var(--transition-color);
}

.sort-list ul li a:hover,
.sort-list ul li.active a {
    background-color: var(--dark-color);
    color: var(--white-color) !important;
}

.sort-data,
.data-popup {
    display: none;
    animation: var(--fade);
}

.sort-data.active,
.data-popup.active {
    display: block;
}

/* ----------- *
    #BANNER
* -------------*/
.banner {
    background-color: var(--light-color);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    background-origin: border-box;
}

.banner .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.banner .content span {
    font-size: var(--fs14);
    text-transform: uppercase;
}

.banner h3 {
    --fs-max: 80px;
}

/* ----------- *
    #FROMBLOG
* -------------*/
.fromblog .dot-title {
    --primary-font: var(--second-font);
    --fs14: 18px;
    --fw600: 400;
    margin-top: 15px;
}

.fromblog .dot-info > a {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid var(--light-color);
    color: var(--grey-color);
    padding: 1px 10px;
    transition: var(--transition-background) var(--transition-color) var(--transition-border);
}

.fromblog .dot-info > a:hover {
    background-color: var(--dark-color);
    color: var(--white-color);
    border-color: var(--dark-color);
}

.fromblog .button {
    text-align: center;
    margin-top: 40px;
}

/* ----------- *
    #FROM INSTAGRAM
* -------------*/
.frominsta .container.wide {
    --mx-width: none;
    --gutter: 0;
}

.frominsta .dotgrid .wrapper {
    background-color: var(--light-color);
}

.frominsta img {
    width: 100%;
}

.frominsta .dot-image {
    margin: 0;
}

.frominsta .actions {
    font-size: 20px;
    width: 50px;
    height: 50px;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
}

.frominsta .scrollto .wrapper::-webkit-scrollbar-thumb {
    --border-color: var(--light-color);
    background-color: var(--grey-color);
}

/* ----------- *
    #FOOTER
* -------------*/
.inner-footer {
    background-color: var(--flashwhite-color);
    line-height: 1.4;
    padding-bottom: 30px;
}

.inner-footer .wrap > div {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 60px;
}

.inner-footer .subscribe {
    width: 100%;
}

.inner-footer .top .subscribe h3 {
    margin-bottom: 20px;
}

.inner-footer p {
    font-size: var(--fs14);
}

.inner-footer .search {
    max-width: 400px;
}

.inner-footer .search .input {
    font-size: var(--fs14);
    border: 2px solid var(--dark-color);
}

.inner-footer .bottom,
.page-single .carousel,
.cart-total .grouping > div {
    padding-top: 40px;
    padding-bottom: 60px;
}

/* ----------- *
    #PAGE SINGLE
* -------------*/
/*
    ___#Breadcrumb_______________
*/
.breadcrumb {
    width: 100%;
    line-height: initial;
    padding: 30px 0 50px;
}

.breadcrumb ul {
    justify-content: center;
}

.breadcrumb ul li {
    position: relative;
    margin-right: 10px;
}

.breadcrumb ul li:not(:first-child) {
    padding-left: 25px;
}

.breadcrumb ul li:not(:first-child)::before {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==");
    background-size: cover;
    background-repeat: no-repeat;
}

.breadcrumb ul :where(li, li a) {
    font-size: var(--fs14);
    padding: 0;
}

.breadcrumb ul li:last-child {
    color: var(--grey-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
    ___#Product Image_______________
*/
.product .wrapper {
    --grid-col: 331px;
}

.product .main-image img {
    width: 94%;
}

.product .outer-thumb {
    display: none;
}

.product .outer-thumb .item {
    height: fit-content !important;
    cursor: pointer;
}

.product .thumb-wrap {
    position: relative;
    width: 70px;
    height: 90px;
    border-radius: 10px;
}

.product .outer-thumb img {
    transition: var(--transition-transform);
}

.product .outer-thumb .item {
    border-radius: 10px;
}

.product .outer-thumb .swiper-slide-thumb-active > div,
.product .outer-thumb .item:hover > div {
    border: 1px solid var(--grey-color);
}

.product .outer-thumb .swiper-slide-thumb-active img,
.product .outer-thumb .item:hover img {
    transform: scale3d(0.85, 0.85, 0.85);
}

.product .custom-pagination {
    position: relative;
    height: 50px;
}

/*
    ___#Product Summary_______________
*/
.summary .entry {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.summary .product-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary .product-price {
    display: flex;
    align-items: center;
}

.summary .product-price .current {
    font-size: 30px;
}

.summary .product-price .wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.summary .product-rating {
    line-height: initial;
    text-align: right;
}

.summary .product-rating a {
    font-size: var(--fs14);
    padding-left: 10px;
    color: var(--grey-color);
    white-space: nowrap;
    transition: var(--transition-color);
}

.summary .product-rating a:hover {
    color: var(--dark-color);
}

.summary .wrap:has(button) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.summary button {
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.summary :where(.wrap,.qty) > button {
    width: 40px;
    height: 40px;
    transition: box-shadow 0.3s, var(--transition-border), var(--transition-background), var(--transition-color);
}

.summary .product-color button {
    border-radius: 50%;
}

.summary .product-color button.tosca {
    background-color: #a9cdcc
}

.summary .product-color button.brown {
    background-color: #966548
}

.summary .product-color button.ocean {
    background-color: #81b8cc
}

.summary .product-color button.selected {
    box-shadow: inset 0 0 0 4px var(--white-color);
}

.summary .product-color button:hover:not(.selected) {
    box-shadow: inset 0 0 0 4px var(--white-color);
}

.summary .product-size button {
    font-size: var(--fs14);
    line-height: 44px;
    border: 1px solid var(--light-color);
    border-radius: 5px;
    background-color: transparent;
}

.summary .product-size button.selected {
    background-color: var(--dark-color);
    color: var(--white-color);
    border-color: var(--white-color);
}

.summary .product-size button:hover:not(.selected, [disabled]) {
    border-color: var(--dark-color);
}

.summary .product-size button:disabled {
    pointer-events: none;
}

.summary .product-stock .wrap,
.summary .product-control a {
    display: flex;
    gap: 5px;
}

.summary .product-stock .wrap i {
    color: var(--primary-color);
    padding-left: 10px;
}

.summary .qty {
    display: flex;
    font-size: 18px;
    min-width: 110px;
    height: 50px;
    margin: 0 20px 20px 0;
    background-color: var(--flashwhite-color);
}

.summary .qty input {
    text-align: center;
    border: 0;
    outline: none;
    background-color: transparent;
}

.summary .qty button {
    font-size: 22px;
    height: 100%;
    background-color: transparent;
}

.summary .qty button:hover {
    color: var(--grey-color);
}

.summary .product-action {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.summary .product-action .buynow {
    grid-column-start: 1;
    grid-column-end: 3;
}

.summary .product-action :where(.addcart,.buynow) button {
    width: 100%;
    height: 50px;
    border-color: var(--dark-color);
}

.summary .product-action .addcart button {
    background-color: var(--white-color);
}

.summary .product-action .addcart button:hover {
    background-color: var(--dark-color);
}

.summary .product-control ul {
    align-items: flex-start;
    flex-flow: wrap;
    gap: 20px;
}

.summary .product-control span {
    font-size: var(--fs14);
}

.summary .product-control a {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
}

.summary .product-control ul li:first-child a:hover {
    color: var(--red-color) !important;
}

.summary .shipping {
    padding-top: 30px;
    border-top: 1px solid var(--flashwhite-color);
}

.summary .shipping li {
    position: relative;
    padding-left: 35px;
    margin-top: 10px;
    line-height: 1.5;
}

.summary .shipping li i {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    line-height: 1;
}

/*
    ___#Product Description/tabbed_______________
*/
.product.detail {
    padding-top: 30px;
    margin: 70px 0;
}

.product nav {
    position: relative;
}

.product nav:before {
    content: '';
    position: absolute;
    left: 0;
    top: 58px;
    height: 2px;
    width: 100%;
    background-color: var(--light-color);
}

.product nav ul li a {
    position: relative;
    display: block;
    font-size: 18px;
    padding: 20px 0;
    margin-right: 30px;
    white-space: nowrap;
    color: var(--grey-color);
}

.product nav ul li a:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -2px;
    background-color: var(--dark-color);
    transition: width 0.3s;
}

.product nav ul li.active a:before {
    width: 100%;
}

.product nav ul :is(li.active a,li a:hover) {
    color: var(--dark-color);
}

.product nav .item-floating {
    right: -18px;
    top: 0;
    background-color: var(--dark-color);
}

.product .product-about {
    padding: 50px 0;
    max-width: 980px;
    margin: 0 auto;
    display: none;
    animation: var(--fade);
}

.product .product-about.active {
    display: block;
}

.product .product-about .text-block {
    padding-bottom: 30px;
}

.product .product-about :where(h3,h4) {
    font-family: var(--primary-font);
    margin-top: 15px;
}

.product .product-about h3 {
    font-size: 22px;
}

.product .product-about h4 {
    font-weight: var(--fw600);
}

.product .product-about ul {
    margin-left: 20px;
    list-style-type: disc;
}

.product.detail .dotgrid .wrapper {
    --grid-col: 250px;
    --gutter: 30px;
}

.product .product-about.custom h3 {
    font-size: 50px;
    font-weight: var(--fw600);
    text-align: center;
    color: var(--light-color);
}

.product .review {
    max-width: 770px;
}

.product .review .head-review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    border-bottom: 1px solid var(--light-color);
}

.product .review .head-review strong {
    font-size: 50px;
}

.product .review .head-review span {
    position: relative;
    padding-left: 20px;
}

.product .head-review span:before {
    height: 50px;
    top: -30px;
}

.product .head-review .primary-btn {
    border-width: 1px;
    text-transform: unset;
    font-weight: 500;
}

.product .profile .thumb-name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.product .profile .image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
}

.product .profile .grouping {
    line-height: initial;
}

.product .profile .name {
    font-weight: var(--fw600);
    margin-top: 5px;
}

.product .profile .date {
    font-size: 12px;
    margin-top: 8px;
}

.review .profile {
    margin-bottom: 20px;
}

/*
    ___#Popup Modal_______________
*/
.data-popup {
    z-index: 1001;
    pointer-events: none;
}

.data-popup .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.data-popup .data-content {
    position: relative;
    background-color: var(--white-color);
    padding: 40px 20px;
    width: var(--data-width, 400px);
    max-width: calc(100vw-50px);
    max-height: calc(100vh-50px);
    border-radius: 5px;
    overflow-y: auto;
    pointer-events: auto;
}

.data-popup .close-trigger,
.data-popup .form span {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    display: flex;
    align-items: center;
}

.data-popup .form {
    position: relative;
}

.data-popup label {
    font-weight: var(--fw600);
    font-size: var(--fs14);
    margin-bottom: 10px;
    display: block;
}

.data-popup .form input {
    color: var(--dark-color);
    background-color: var(--light-color);
    border: 0;
    padding: 7px 34px 7px 10px;
    border-radius: 3px;
    font-size: var(--fs14);
}

.data-popup .form span {
    top: auto;
    bottom: 0;
    right: 10px;
    cursor: pointer;
    transition: var(--transition-color);
}

.data-popup .form span:hover {
    color: var(--grey-color);
}

.copy {
    color: var(--dark-color);
    background-color: var(--light-color);
    border: 0;
    padding: 7px 34px 7px 10px;
    border-radius: 3px;
    font-size: var(--fs14);
}

.data-popup form > div {
    margin-top: 20px;
}

form .button button,
.d-review .starts label {
    cursor: pointer;
}

.data-popup h3, form .button {
    text-align: center;
}

.d-review .data-content {
    --data-width: 500px;
    border-radius: 15px;
}

.d-review .dotgrid .wrapper {
    --gutter: 10px;
}

.d-review .starts input {
    display: none;
}

.d-review .starts label {
    font-size: 16px;
    margin: 0;
}

.d-review :where(.rating,.starts) {
    display: flex;
    gap: 7px;
}

.d-review .starts > input:checked ~ label {
    color: #ffa502;
}

.d-review .starts {
    flex-direction: row-reverse;
}

/*
    ___#Floating cart_______________
*/
.cart-menu .wrap {
    float: right;
    --transX: 100%;
}

.cart-menu .close-trigger {
    right: auto;
    left: -40px;
    color: red;
}

.cart-menu .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cart-menu .cart-outer > .wrapper {
    height: 100vh;
    padding: 0;
}

.cart-menu .cart-header {
    padding: 30px 0;
}

.product-list li {
    margin-bottom: 30px;
}

.product-list li .grouping {
    position: relative;
    float: left;
    padding-left: 45px;
}

.product-list .quantity {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 30px;
}

.product-list .quantity .control {
    background-color: var(--flashwhite-color);
    border-radius: 5px;
}

.product-list .quantity .control > * {
    height: 30px;
    border: 0;
    outline: 0;
    text-align: center;
    background-color: transparent;
}

.product-list .quantity button {
    font-size: 20px;
    line-height: initial;
    width: 100%;
    cursor: pointer;
}

.product-list .thumbnail {
    width: 70px;
    margin-right: 20px;
}

.product-list .variants {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.product-list .variants h4 {
    line-height: initial;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-list .variants > div {
    font-size: var(--fs14);
    line-height: 1.4;
}

.product-list .item-remove {
    position: absolute;
    right: 0;
    bottom: 0;
}

.product-list .item-remove {
    color: var(--red-color);
    background-color: #ff47571a;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: var(--transition-color), var(--transition-background);
}

.product-list .item-remove:hover {
    background-color: var(--dark-color);
    color: var(--white-color);
    border-color: var(--white-color);
}

.cart-menu .cart-footer {
    margin: 0 -20px;
    padding: 20px;
    background-color: var(--flashwhite-color);
    margin-top: auto;
}

.cart-footer form {
    position: relative;
    margin-bottom: 20px;
}

.cart-footer form .input {
    line-height: 34px;
    border: 0;
    border-radius: 7px;
    background-color: var(--white-color);
}

.cart-footer form .submit {
    position: absolute;
    top: 10px;
    right: 10px;
    max-width: 90px;
    height: 34px;
    line-height: 1;
    background-color: var(--grey-color);
    color: var(--white-color);
    padding: 0;
    border-radius: 7px;
    cursor: pointer;
    transition: var(--transition-background);
}

.cart-footer form .submit:hover {
    background-color: var(--dark-color);
}

.cart-footer .math-pricing ul li {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.cart-footer .math-pricing ul ul {
    width: 100%;
}

.cart-footer .math-pricing ul li span:not(.value),
.cart-footer .math-pricing ul li label {
    color: var(--grey-color);
}

.cart-footer .math-pricing ul ul li:not(:first-child) {
    padding-left: 20px;
}

.cart-footer .math-pricing .total {
    font-size: 18px;
    font-weight: var(--fw600);
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--light-color);
}

.cart-footer .button {
    text-align: center;
}

.cart-footer .button .secondary-btn {
    display: block;
    margin: 20px 0 10px;
}

.cart-footer .button .secondary-btn,
.cart-total .button a.secondary-btn {
    background-color: var(--primary-color);
    border: 0;
    text-transform: uppercase;
    line-height: 54px;
    border-radius: 7px;
    min-width: 240px;
    text-align: center;
}

.cart-footer .button .secondary-btn:hover,
.cart-total .button a.secondary-btn:hover {
    background-color: var(--grey-color);
    color: var(--white-color);
}

.cart-menu .product-list > .wrapper {
    max-height: 400px;
}

/*  -------------------------------*
    #Products page
*   ------------------------------*/
.sidebar-title {
    font-size: 26px;
    padding-top: 100px;
}

.page-category .sidebar .widget {
    margin: 2px 0;
}

.page-category .sidebar label,
.page-category .sidebar .price-range {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.page-category .sidebar label i {
    font-size: 26px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-transform);
}

.page-category .sidebar .list-block ul {
    padding-left: 15px;
    margin-top: 20px;
    height: 230px;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background-color: var(--light-color);
    box-shadow: none;
    border: 0;
}

input[type='range']::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    height: 18px;
    width: 14px;
    background-color: var(--grey-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}

.page-category .sidebar .price-range {
    font-size: 18px;
}

.page-category .sidebar .widget .accord {
    padding-left: 15px;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.3s ease;
}

.page-category .sidebar .widget input:checked ~ div {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
}

.page-category .sidebar .widget input:checked ~ label i {
    transform: rotate(180deg);
}

.page-category .sidebar .widget :where(.accord > div,.range-track) {
    padding-top: 20px;
}

/*
    ___#Product category_______________
*/
.category-content .button {
    margin: 60px 0 100px;
    text-align: center;
}

.category-content :where(.sorter, .right) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-content :where(.sorter) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-content .sorter {
    margin: 0 0 30px;
}

.category-content .sorter > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    background-color: var(--dark-color);
    color: var(--white-color);
    border-radius: 50%;
    position: fixed;
    top: calc(50% - 20px);
    left: 0;
    z-index: 10;
    opacity: 0.5;
    transition: opacity 0.5s, var(--transition-transform);
}

.category-content .sorter > a:hover {
    transform: scale(1.2);
    opacity: 1;
}

.category-content .opt-trigger {
    gap: 5px;
    margin-right: 15px;
    line-height: 1.4;
}

.category-content .opt-trigger span {
    font-size: var(--fs14);
    font-weight: var(--fw600);
}

.category-content .opt-trigger i {
    font-size: 24px;
}

.category-content .sort-list ul {
    width: 180px;
}

.category-content .right .list-inline a {
    display: flex;
    padding: 7px;
    background-color: var(--flashwhite-color);
    margin-left: 10px;
    border-radius: 3px;

}

.category-content .left span {
    display: none;
}

.category-content .dotgrid .wrapper {
    --grid-col: 221px;
}

/*  -------------------------------*
    #Cart page
*   ------------------------------*/
.page-cart .product-list {
    position: relative;
}

.has-bg::before, .has-bg::after {
    /*content: '';*/
    position: absolute;
    height: 100%;
    z-index: -1;
    /*background-color: var(--flashwhite-color);*/
}

.has-bg::before {
    top: auto;
    left: 0;
    width: 50%;
}

.has-bg::after {
    top: 0;
    right: 100%;
    width: 100%;
}

.page-cart .product-list .item-remove {
    position: relative;
}

.page-cart .product-list li,
.page-cart .cart-total .grouping,
.page-checkout .content {
    display: grid;
    margin: 0;
}

.cart-table .product-list .table-title {
    font-size: var(--fs14);
    font-weight: var(--fw600);
    text-transform: uppercase;
}

.page-cart .product-list li .grouping {
    --grid-col: 75px;
    padding: 30px 0;
    align-items: center;
}

.cart-table .product-list li .grouping {
    --grid-col: 75px;
    padding: 30px 0;
    align-items: center;
}

.cart-table .product-list .grouping:last-child {
    border-top: 1px solid var(--flashwhite-color);
    text-align: center;
    --gutter: 0;
}

.cart-table .product-list .thumbnail,
.page-checkout .product-list .thumbnail {
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0;
}

.cart-table .product-list .quantity {
    position: relative;
    max-width: 100px;
    margin: 0 auto;
}

.cart-table .product-list .control {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 auto;
}

.cart-table .product-list .thumbnail,
.cart-table .item-remove {
    margin-left: auto;
    margin-right: auto;
}

.cart-table .product-list :where(.table-title, .price) {
    display: none;
}

.cart-total .has-bg::before, .cart-total .has-bg::after {
    content: '';
    background-color: var(--dark-color);
    width: 100%;
    bottom: auto;
    top: 0;
}

.cart-total .has-bg::before {
    right: 100%;
    left: auto;
}

.cart-total .has-bg::after {
    left: 100%;
}

.cart-total .product-list {
    background-color: var(--dark-color);
    color: var(--white-color);
}

.cart-total .grouping {
    font-weight: var(--fw600);
    text-transform: uppercase;
}

.cart-total .add-note {
    text-align: center;
}

.cart-total .add-note textarea {
    max-width: 300px;
    border-color: transparent;
    outline: 0;
    color: var(--white-color);
    background-color: transparent;
    text-transform: uppercase;
    padding: 15px;
    border-radius: 10px;
    transition: var(--transition-color);
}

.cart-total .add-note :is(textarea:hover,textarea:focus, :not(:placeholder-shown)) {
    border-color: var(--grey-color);
}

.cart-total .add-note textarea:not(:placeholder-shown) {
    text-transform: uppercase;
    font-weight: initial;
}

.cart-total .sub-total {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 400px;
    margin: 0 auto;
}

.cart-total .sub-total-price {
    padding-left: 30px;
    font-size: 26px;
}

.cart-total .sub-terms {
    font-weight: initial;
    text-transform: capitalize;
}

/*  -------------------------------*
    #Checkout page
*   ------------------------------*/
.checkout-products {
    position: relative;
    margin: 0 -15px;
    padding: 40px 15px 0;
}

.page-checkout h2 {
    font-size: 30px;
    margin-bottom: 30px;
}

.page-checkout .checkout-address {
    margin-bottom: 40px;
}

.page-checkout form > div,
.page-checkout form > div input ~ input {
    margin-top: 20px;
}

.page-checkout form > div label {
    display: inline-block;
    margin: 0 0 5px;
}

.checkout-products .product-list li {
    min-height: 120px;
}

.checkout-products .product-list .grouping {
    padding: 0;
    margin-right: 20px;
}

.checkout-products .item-floating {
    top: -11px;
    right: -7px;
    transform: scale(1.5);
    z-index: 1;
}

.checkout-products.has-bg::before {
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
}

.checkout-products.has-bg::after,
.total-summary.has-bg::after {
    width: 100%;
    left: 100%;
    top: 0;
}

.checkout-products > div .wrapper {
    max-width: 400px;
    line-height: 34px;
    margin: 0 auto;
}

.checkout-products .total-summary {
    position: relative;
    padding: 10%;
    background-color: var(--dark-color);
    color: var(--white-color);
}

.total-summary.has-bg::after {
    background-color: var(--dark-color);
    z-index: 0;
}

.checkout-products .payment-options {
    text-align: center;
    margin-top: 30px;
}

/*  -------------------------------*
    #MObile nav
*   ------------------------------*/
.mobile-nav {
    top: auto;
    background-color: var(--white-color);
    box-shadow: var(--shadow);
    z-index: 888;
}

.mobile-nav a {
    position: relative;
    font-size: 20px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    color: var(--dark-color);
    border-radius: 3px;
    transition: var(--transition-background), var(--transition-color);
}

.mobile-nav a:hover {
    color: var(--primary-color);
    background-color: #2bcbba1a;
}

.mobile-nav span {
    right: -6px;
    top: -4px;
}

.mobile-nav > div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    height: 54px;
}

.form-signin {
    width: 90%;
}

@media (min-width: 481px) {
    .product .wrapper {
        --gutter: 60px;
    }

    .product.dotgrid .image {
        position: relative;
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        height: min-content;
        overflow: hidden;
    }

    .product .outer-main {
        width: calc(100% - 85px);
        height: 100%;
        margin: 0 0 0 85px;
    }

    .product .outer-thumb {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .product .custom-pagination {
        display: none;
    }

    .product .profile .comment {
        padding-left: 92px;
    }

    .swiper-pagination {
        display: none;
    }

    .form-signin {
        width: 70%;
    }
}

@media (min-width: 768px) {
    .banner {
        background-size: contain;
    }

    .product.dotgrid .image {
        max-width: 100%;
        margin: 0;
        position: sticky;
        top: 30px;
    }

    .product nav ul {
        justify-content: center;
    }

    .cart-table .product-list :where(.table-title, .price),
    .category-content .left span {
        display: block;
    }

    .page-cart .product-list li,
    .page-cart .cart-total .grouping,
    .page-checkout .content {
        grid-template-columns: 1fr 1fr;
    }

    .has-bg::before, .has-bg::after {
        content: '';
        background-color: var(--flashwhite-color);
    }

    .cart-total .sub-total {
        padding-left: 10%;
        margin: 0;
    }

    .checkout-products {
        margin: 0;
        padding: 0;
    }

    .checkout-address {
        padding-right: 10%;
    }

    .checkout-products .order-summary {
        padding: 10% 0 0 10%;
    }

    .checkout-products > div .wrapper,
    .checkout-products .total-summary {
        margin: 0;
    }

    .mobile-nav {
        display: none;
    }

    .form-signin {
        width: 50%;
    }

    .sidebar .wrap .sidebar-content {
        width: 200px !important;
        font-size: 16px;
        margin-bottom: 0;
    }

    .page-category .sidebar .close-trigger {
        left: 210px;
    }

    .heading {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .menu-trigger {
        display: none;
    }

    .header-center nav {
        display: grid;
    }

    .header-left .list-inline {
        display: flex;
    }

    /*
    sub-menu
    */
    .mega-content {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 60px;
    }

    .mega-content .links {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
        gap: 15px;
    }

    nav.menu .sub-mega {
        left: 0;
        right: 0;
        padding: 50px 0;
        display: none;
    }

    nav.menu :where(.sub-menu, .sub-mega) {
        position: absolute;
        top: auto;
        line-height: initial;
        background-color: var(--white-color);
        box-shadow: var(--shadow);
        z-index: 1000;
        display: none;
        animation: var(--fade);
    }

    nav.menu li:hover :where(.sub-menu, .sub-mega) {
        display: block;
    }

    nav.menu .sub-menu {
        padding: 10px 0;
    }

    nav.menu .sub-menu li {
        padding: 5px 30px;
    }

    /*
        SLIDER
        */
    .slider .ob-cover {
        height: calc(100vh - 80px);
    }

    .slider .title-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
    }

    .slider h3 {
        --fs-max: 80px;
        color: var(--white-color);
    }

    .slider .button a {
        border-color: var(--white-color);
        color: var(--white-color);
    }

    .slider .button a:hover {
        background-color: var(--dark-color);
    }

    .scrollto .wrapper:not(.initial) {
        --display: grid;
        padding: 0;
        overflow: unset;
        scroll-snap-type: unset;
        overscroll-behavior: unset;
    }

    .scrollto .wrapper .item {
        width: unset;
    }

    .inner-footer .wrap > div {
        flex-flow: nowrap;
    }

    /*.page-category .content {*/
    /*    display: grid;*/
    /*    grid-template-columns: 1fr 4fr;*/
    /*    gap: 60px;*/
    /*}*/
    .sidebar-title,
    .sidebar-content {
        padding: 0;
    }

    /*.sidebar,*/
    /*.sidebar > .wrap {*/
    /*    all: unset;*/
    /*}*/
}

@media (max-width: 480px) {
    .carousel .dotgrid .item {
        width: 270px;
    }

    .button > [class*=-btn] {
        --fs14: 18px;
        --fw600: 400;
        padding: 7px 15px;
        line-height: 20px;
    }

    .product .review .head-review strong {
        font-size: 30px;
    }

}


@media (min-width: 1200px) {
    .container.wide {
        --mx-width: 1720px;
        --gutter: 40px;
    }

    .form-signin {
        width: 25%;
    }
}

@media (min-width: 1400px) {
    .mega-content {
        grid-template-columns: 1fr 1fr;
    }

    .carousel .inner-wrapper {
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 40px;
    }

    .carousel .wrapper .nav > .swiper-button-next {
        right: -40px;
    }

    .carousel .wrapper .nav > .swiper-button-prev {
        left: -40px;
    }

    .product .outer-main {
        width: 90%;
        padding: 0;
    }

    .product .image .outer-thumb {
        position: absolute;
        top: 0;
        height: 100%;
    }
}

/*new ccs */
.image img {
    border-radius: 10px;
}

ul.grey-color {
    list-style: initial;
}

span.heart {
    background-color: red !important;
}

.page-category .dotgrid .wrapper .item {
    max-width: 33vh;
}

.sort-list ul {
    padding: 0;
}

.delete-icon:hover {
    background-color: red !important;
}

.favourite {
    position: absolute;
    color: black;
    z-index: 2;
    top: 5px;
    right: 2px;
    font-size: 25px;
    width: 42px;
    display: flex;
    height: 42px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item .ri-heart-fill {
    color: red;
}

.category-container {
    display: flex;
    -moz-border-radius-topleft: 50px; /* Set your desired border radius */
    height: 50px; /* Set your desired height */
    overflow: hidden; /* Ensure the border radius is applied to the image */
    background-color: #474747;
    color: white;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 25px 25px;
    border-bottom-right-radius: 25px 25px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}

.category-container:hover {
    background-color: white;
    color: #474747;
}

.category-container img {
    width: 45px; /* Make the image fill the container */
    height: 45px; /* Make the image fill the container */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    margin-right: 8px;
    margin-left: 8px;
}

.category-container .text-container {
    flex: 1;
    padding: 10px; /* Add padding to the text container */
}

.category-container .picture-name {
    margin: 0; /* Remove default margin */
    text-transform: capitalize;
}

.close-trigger:hover {
    background-color: #fff;
    color: #474747;
}

.file-upload {
    border-radius: 10px;
}

.file-upload label {
    line-height: 1.25;
    padding: 0.74rem 0.75rem;
}

.category-select {
    line-height: 1.25;
    padding: 1.05rem 0.75rem;
}

.product-category {
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: capitalize;
}

.vendor-code {
    display: flex;
    align-items: center;
    gap: 10px;
}

.category-product-image {
    width: 300px;
    height: 300px;
    object-fit: contain; /* Tasvirni butunlay ko'rsatadi, cho'zilmasdan */
    display: block;
    margin: auto; /* Markazga joylash uchun */
    background-color: white; /* Agar tasvir konteynerni to'ldirmasa, orqa fon rangini ko'rish mumkin */
}



.sum-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .category-container {
        height: 70px;
        font-size: 16px;
    }

    .category-container img {
        height: 60px;
        width: 60px;
    }
}

@media (max-width: 552px) {
    .category-content .dotgrid .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}