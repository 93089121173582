.my-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    overflow: hidden;
    position: relative;
}

.my-container {
    max-width: 1230px;
}

@media (max-width: 500px) {
    .my-container {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .my-container {
        padding: 0 30px;
    }

    .my-container .content__holder .my-sidebar {
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .my-container {
        max-width: 768px;
    }
}

@media (max-width: 1250px) {
    .my-container {
        max-width: 991px;
    }
}

.content__holder {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    margin: 70px 0;
}

.content__main {
    top: 5px;
    margin-top: 15%;
}

.my-sidebar {
    width: 100%;
    top: 5px;
    height: 100%;
    margin-top: 15%;
}

.sidebar__socials-item {
    margin-bottom: 20px;
}

.sidebar__socials-title {
    color: #4d4d4d;
    font-size: 18px;
}

.sidebar__socials-bold {
    display: inline-flex;
    color: #1e1e1e;
    font-size: 24px;
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: bold;
}

.bold-text {
    font-weight: bold;
    font-family: "Gilroy-Bold", sans-serif;
}

.sidebar__socials-item a {
    color: #1e1e1e;
    text-decoration: none;
    background-color: transparent;
}

.sidebar__socials-item a,.sidebar__socials-item a:hover,.sidebar__socials-item a:focus,.sidebar__socials-item a:active {
    color: #1e1e1e !important;
    text-decoration: none !important;
    outline: none;
}

.sidebar__socials-text {
    font-size: 18px;
    color: #1e1e1e;
    line-height: 1.4;
    margin-top: 14px;
}

.sidebar__socials-item .h6 {
    font-size: 1rem;
}

.sidebar__socials-item .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.sidebar__socials-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.content__top-secondary {
    width: 100%;
    top: 10%;
    height: 100%;
    margin-top: 5%;
}

.content__top {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.content__title {
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #1e1e1e;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    margin-bottom: 0;
}

.map__block {
    border-radius: 14px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 600px;
}

.content__wrapper {
    margin-top: 42px;
}