.news-list .flexSB {
    display: flex;
    align-items: center;
}

.news-list .images {
    position: relative;
}

.news-list .title {
    font-weight: 500;
    margin-bottom: 15px;
}

.news-list .date i {
    width: 30px;
    font-size: 13px;
    color: #0f1508;
}

.news-list .date label {
    font-size: 13px;
    color: #868585;
}

.news-list .desc {
    font-size: 13px;
    margin: 10px 0;
}

.news-list .shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 10px 15px 10px -10px;
}

.news-list .box {
    margin-left: 2px;
    border-radius: 10px;
}

.news-list .text {
    padding: 0 30px;
}

.news-list .item {
    height: 150px;
}

.news-list .images {
    min-width: 200px;
    width: 200px;
    min-height: 150px;
}

.news-list img {
    min-width: 200px;
    width: 200px;
    min-height: 150px;
    object-fit: cover;
}

.news-list h1 {
    font-size: 22px;
}

.news-list .heading .title {
    font-size: clamp(30px, -0.875em + 8.333vw, 40px);
}

.news-list {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 20px 20px;
    }

    .news-list h1 {
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .news-list .images,.news-list img {
        min-width: 150px;
        width: 150px;
        min-height: 113px;
    }

    .news-list .text {
        padding: 10px 15px;
    }

    .container {
        padding: 15px 10px;
    }
}
