.about_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
}

.react-player__preview {
    border-radius: 15px;
}

video {
    border-radius: 15px;
}

.about_container .content__top {
    margin-top: 5%;
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.about_container .content__title {
    font-weight: bold;
    font-size: 24px;
    color: #666666;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 720px;
    margin-right: 20px;
    margin-bottom: 0;
}

.about_container .content__text {
    color: #232455;
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.content__text .work {
    font-size: 14px;
    line-height: 1.8;
    color: #666666;
    margin-bottom: 20px;
}

.content__text strong {
    font-family: "SFProDisplay-Bold", sans-serif;
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.3;
}

.video_content {
    width: 100%;
}

