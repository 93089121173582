main {
    margin-top: 10px;
}

main hr {
    border-top: 2px solid #151515;
    margin: 20px 0 20px 0;
}

.details .title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
}

.details .date {
    display: flex;
    align-items: center;
    justify-content: right;
    font-weight: 500;
    margin: 0 20px;
}

.details .date label {
    color: grey;
}

.details .date i {
    width: 20px;
    font-size: 13px;
    color: #0f1508;
}

.details img {
    margin: 30px 0;
}

.body p, .news-create p {
    margin: 10px 0;
    font-size: 20px;
}

.body h1 {
    font-weight: 600;
    font-size: 30px;
    margin-top: 30px;
}

.details .slider img {
    object-fit: cover;
}

.news-create .quill {
    background-color: #fff;
    border-radius: 10px;
}

.news-create .ql-toolbar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.news-create .ql-container {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media screen and (min-width: 1200px) {
    main .container {
        padding: 45px 100px;
    }
}

@media screen and (max-width: 768px) {
    .details {
        padding-right: 0;
    }

    .details img {
        width: 100%;
    }

    .details .title {
        font-size: 22px;
        line-height: 33px;
        font-weight: 600;
    }

    .body p, .news-create p {
        margin: 9px 0;
        line-height: 20px;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .details .title {
        font-size: 18px;
        line-height: 27px;
    }

    .body p, .news-create p {
        margin: 7px 0;
        line-height: 17px;
        font-size: 13px;
    }

    main .container {
        padding: 20px 5px;
    }
}