.undefined {
    height: 400px;
    width: 400px; /* Kenglikni belgilash zarur */
    object-fit: contain;
    display: block;
    margin: auto;
    background-color: white;
    border-radius:15px; /* Barcha burchaklarni 8px ga yumshatish */
}


.thumb-wrap .undefined {
    object-fit: contain;
    display: block;
    margin: auto;
    background-color: white;
}